import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import CommentListRow from "./CommentListRow";

const CommentList = () => {
  const comments = useSelector((state) => state.candidate.comments);
  const dispatch = useDispatch();

  return (
    <Row className={"mx-0 mt-3"}>
      <Col>
        {comments.length > 0 ? (
          <Card className={"default-card py-3 px-4"}>
            {comments.map((comment, index) => {
              const lastItem = index === comments.length - 1;
              return <CommentListRow key={index} comment={comment} lastItem={lastItem}></CommentListRow>;
            })}
          </Card>
        ) : (
          <Card className={"default-card py-3 px-4"} style={{ backgroundColor: "#f7f7f7" }}>
            <Row className={"justify-content-center"}>
              <Col xs={"auto"} className={"text-20 text-color-gray-light fw-medium"}>
                {" "}
                No comment available
              </Col>
            </Row>
          </Card>
        )}
      </Col>
    </Row>
  );
};
export default CommentList;
