import React from "react";
import Card from "react-bootstrap/Card";
import { Col, Row } from "react-bootstrap";
import StatisticTimePeriod from "./StatisticTimePeriod";
import { useDispatch, useSelector } from "react-redux";
import { SetStatisticsModalIsShown } from "../../Store";

const Statistics = () => {
  const dispatch = useDispatch();

  const statistics = useSelector((state) => state.adminDash.companyStatistics);

  return (
    <Card
      className={"default-card hover-shadow h-100 py-2"}
      onClick={() => {
        dispatch(SetStatisticsModalIsShown(true));
      }}
    >
      <Row className={"mx-0 h-25"}>
        <Col className={"align-content-center"} style={{ borderBottom: "3px solid #E7ECEF", margin: "0 24px" }}>
          <StatisticTimePeriod
            headerText={"BUGÜN"}
            color={"#9155FD"}
            completedCount={statistics?.lastDayFinishedCount}
            assignedCount={statistics?.lastDaySentCount}
          />
        </Col>
      </Row>
      <Row className={"mx-0 h-25"}>
        <Col className={"align-content-center"} style={{ borderBottom: "3px solid #E7ECEF", margin: "0 24px" }}>
          <StatisticTimePeriod
            headerText={"BU HAFTA"}
            color={"#56CA00"}
            completedCount={statistics?.last7DaysFinishedCount}
            assignedCount={statistics?.last7DaysSentCount}
          />
        </Col>
      </Row>
      <Row className={"mx-0 h-25"}>
        <Col className={"align-content-center"} style={{ borderBottom: "3px solid #E7ECEF", margin: "0 24px" }}>
          <StatisticTimePeriod
            headerText={"BU AY"}
            color={"#FFB400"}
            completedCount={statistics?.last30DaysFinishedCount}
            assignedCount={statistics?.last30DaysSentCount}
          />
        </Col>
      </Row>
      <Row className={"mx-0 h-25"}>
        <Col className={"align-content-center"} style={{ margin: "0 12px" }}>
          <StatisticTimePeriod
            headerText={"SON 3 AY"}
            color={"#16B1FF"}
            completedCount={statistics?.last90DaysFinishedCount}
            assignedCount={statistics?.last90DaysSentCount}
          />
        </Col>
      </Row>
    </Card>
  );
};
export default Statistics;
