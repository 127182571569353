export const ParseDate = (dateString) => {
  const offsetInMinutes = new Date().getTimezoneOffset(); // Negative for GMT+ zones
  const offsetInMilliseconds = -offsetInMinutes * 60 * 1000; // Convert to milliseconds
  // Local timezone offset

  // Parse the input date string
  const dateObject = new Date(dateString);

  // Add the offset to adjust for the local timezone
  const adjustedDateObject = new Date(dateObject.getTime() + offsetInMilliseconds);

  const year = adjustedDateObject?.getFullYear().toString().substring(2);
  const month = (adjustedDateObject?.getMonth() + 1).toString().padStart(2, "0");
  const day = adjustedDateObject?.getDate().toString().padStart(2, "0");
  const hour = adjustedDateObject?.getHours().toString().padStart(2, "0");
  const minute = adjustedDateObject?.getMinutes().toString().padStart(2, "0");

  const time = `${hour}:${minute}`;
  const date = `${day}-${month}-${year}`;

  return { time: time, date: date };
};

export const GetTimeDifference = (date1, date2) => {
  const date1Object = new Date(date1);
  const date2Object = new Date(date2);

  const diffInMs = Math.abs(date2Object - date1Object);

  const hours = Math.floor(diffInMs / (1000 * 60 * 60));
  const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

  return { hours, minutes };
};
