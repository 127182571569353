import Modal from "react-bootstrap/Modal";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SetStatisticsModalIsShown } from "../../Store";
import { Button, Card, Col, Row } from "react-bootstrap";
import { getFinishedApplicationsByDaysCount, getSentApplicationsByDaysCount } from "../../Store/Thunk";
import Form from "react-bootstrap/Form";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";
import { ParseDate } from "../../../../../Helpers/DateHelper";

const StatisticsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isShown = useSelector((state) => state.adminDash.statisticsModalIsShown);
  const finishedApplications = useSelector((state) => state.adminDash.finishedApplicationsByDaysCount);
  const sentApplications = useSelector((state) => state.adminDash.sentApplicationsByDaysCount);
  const windowSizeCssClass = useSelector((state) => state.appMenu.screenSize);

  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // Add one day
    return tomorrow.toISOString().split("T")[0]; // Format: YYYY-MM-DD
  };

  const getThreeDaysBeforeToday = () => {
    const pastDate = new Date();
    pastDate.setDate(pastDate.getDate() - 3); // Move back 3 days
    return pastDate.toISOString().split("T")[0]; // Format: YYYY-MM-DD
  };

  const [startDate, setStartDate] = useState(getThreeDaysBeforeToday());
  const [endDate, setEndDate] = useState(getTomorrowDate());
  const [isTypeFinished, setIsTypeFinished] = useState(false);

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(getSentApplicationsByDaysCount({ startDate, endDate }));
      dispatch(getFinishedApplicationsByDaysCount({ daysCount: 45 }));
    }
  }, [startDate, endDate, dispatch]);

  const bundledSentApplications = useMemo(() => {
    if (!sentApplications) return [];

    return sentApplications.reduce((acc, application) => {
      const date = application.sentDate;

      let dateGroup = acc.find((group) => group.date === date);
      if (!dateGroup) {
        dateGroup = { date, candidates: [] };
        acc.push(dateGroup);
      }

      let candidateGroup = dateGroup.candidates.find(
        (candidate) => candidate.candidateName === application.candidateName
      );
      if (!candidateGroup) {
        candidateGroup = { candidateName: application.candidateName, applications: [] };
        dateGroup.candidates.push(candidateGroup);
      }

      candidateGroup.applications.push(application.applicationName);

      return acc;
    }, []);
  }, [sentApplications]);

  return (
    <Modal
      size={"xl"}
      show={isShown}
      onHide={() => {
        dispatch(SetStatisticsModalIsShown(false));
      }}
      dialogClassName={`custom-modal-dialog ${windowSizeCssClass}`}
      contentClassName={"custom-modal-content"}
    >
      <div className={"bordered"}>
        <Modal.Header closeButton className={"p-0 border-bottom-0 mb-3"}>
          <Col>
            <Row className={"justify-content-between"}>
              <Col xs={"auto"} className={"align-content-center"}>
                <Row className={"mx-0"}>
                  <Col className={"align-content-center"}>
                    <Button
                      className={"button primary"}
                      disabled={isTypeFinished}
                      onClick={() => setIsTypeFinished(true)}
                    >
                      Finished
                    </Button>
                  </Col>
                  <Col className={"align-content-center"}>
                    <Button
                      className={"button primary"}
                      disabled={!isTypeFinished}
                      onClick={() => setIsTypeFinished(false)}
                    >
                      Sent
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs={"auto"} className={"align-content-center"}>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>{t("Start Date")}</Form.Label>
                      <Form.Control
                        type="date"
                        value={startDate}
                        max={endDate || getTomorrowDate()}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>{t("End Date")}</Form.Label>
                      <Form.Control
                        type="date"
                        value={endDate}
                        min={startDate}
                        max={getTomorrowDate()}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Modal.Header>

        <Row className={"justify-content-between"}>
          <Col className={"align-content-center"}>
            <Card className={"default-list"} style={{ backgroundColor: "#f8f9fa" }}>
              <Card className={"header mb-0"} style={{ backgroundColor: "white" }}>
                <Row className={"text-20 text-color-gray-light fw-medium"} style={{ height: "50px", margin: "0" }}>
                  <Col xs={3} className={"align-content-center"}>
                    <Row className={"justify-content-center"}>Tarih</Row>
                  </Col>
                  <Col className={"align-content-center"}>
                    <Row>
                      <Col>
                        <Row className={"justify-content-center"}>Adaylar</Row>
                      </Col>
                      <Col>
                        <Row className={"justify-content-center"}>Değerleme</Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>

              <ScrollableCard bottom={80}>
                {!isTypeFinished
                  ? bundledSentApplications?.map((sentApplicationsBundle, i) => (
                      <Card
                        key={i}
                        className={"mt-4 list-container text-20 fw-medium text-color-gray-dark"}
                        style={{ backgroundColor: "white" }}
                      >
                        <Row className={"list-row "} style={{ borderBottomWidth: 0 }}>
                          <Col xs={3} className={"align-content-center text-color-gray-light"}>
                            <Row className={"justify-content-center"} style={{ borderRight: "2px solid #e7ecef" }}>
                              {ParseDate(sentApplicationsBundle.date).date}{" "}
                            </Row>
                            <Row className={"justify-content-center"} style={{ borderRight: "2px solid #e7ecef" }}>
                              {ParseDate(sentApplicationsBundle.date).time}
                            </Row>
                          </Col>
                          <Col>
                            {sentApplicationsBundle.candidates?.map((candidate, index) => {
                              const isLastItem = sentApplicationsBundle.candidates.length === index + 1;

                              return (
                                <Row key={index} style={{ borderBottom: `${isLastItem ? "0" : "2"}px solid #e7ecef` }}>
                                  <Col className={"align-content-center "} style={{ borderRight: "2px solid #e7ecef" }}>
                                    <Row
                                      className={"justify-content-center h-100"}
                                      style={{ paddingBottom: "10px", paddingTop: "10px" }}
                                    >
                                      <Col xs={"auto"} className={"align-content-center"}>
                                        {candidate.candidateName}
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col className={"align-content-center py-3"}>
                                    {candidate.applications.map((applicationName, applicationIndex) => {
                                      const isLastItem = candidate.applications.length === applicationIndex + 1;

                                      return (
                                        <Row key={applicationIndex} className={"justify-content-center"}>
                                          <Col
                                            xs={"auto"}
                                            className={"align-content-center px-4"}
                                            style={{ borderBottom: `${isLastItem ? "0" : "2"}px solid #e7ecef` }}
                                          >
                                            {applicationName}
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                  </Col>
                                </Row>
                              );
                            })}
                          </Col>
                        </Row>
                      </Card>
                    ))
                  : finishedApplications?.map((finishedApplication, i) => (
                      <Card
                        key={i}
                        className={"mt-4 list-container text-20 fw-medium text-color-gray-dark"}
                        style={{ backgroundColor: "white" }}
                      >
                        <Row className={"list-row "} style={{ borderBottomWidth: 0 }}>
                          <Col
                            xs={3}
                            className={"align-content-center text-color-gray-light"}
                            style={{ borderRight: "2px solid #e7ecef" }}
                          >
                            <Row className={"justify-content-center"}>
                              {ParseDate(finishedApplication.finishDate).date}{" "}
                              {ParseDate(finishedApplication.finishDate).time}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    ))}
              </ScrollableCard>
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default StatisticsModal;
