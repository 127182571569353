import { useSelector } from "react-redux";
import React, { useState } from "react";
import AssignedAssessmentsListRow from "./AssignedAssessmentsListRow";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";

const AssignedAssessmentsList = () => {
  const tickets = useSelector((state) => state.candidate.candidate.tickets);
  const [windowHeight, setWindowHeight] = useState();

  const reversedTickets = tickets.slice().reverse();

  return (
    <Row className={"mx-0 mt-3"}>
      {tickets.length > 0 ? (
        <Col>
          {reversedTickets?.map((ticket, index) => {
            return <AssignedAssessmentsListRow ticket={ticket} key={index}></AssignedAssessmentsListRow>;
          })}
        </Col>
      ) : (
        <Col>
          <Card className={"default-card py-3 px-4"} style={{ backgroundColor: "#f7f7f7" }}>
            <Row className={"justify-content-center"}>
              <Col xs={"auto"} className={"text-20 text-color-gray-light fw-medium"}>
                No assessments available
              </Col>
            </Row>
          </Card>
        </Col>
      )}
    </Row>
  );
};
export default AssignedAssessmentsList;
