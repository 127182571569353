import Modal from "react-bootstrap/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { SetSelectedResult } from "../../../Store";
import SessionImages from "./SessionImages";
import { GetTimeDifference, ParseDate } from "../../../../../../Helpers/DateHelper";
import DottedLineUi from "./DottedLineUi";
import { GetKeyByValue, warningFlagTypeEnum } from "../../../../../../Helpers/EnumHelper";
import ScrollableCard from "../../../../../SharedComponents/ScrollableCard";

const SessionsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedResult = useSelector((state) => state.candidate.selectedResult);
  const windowSizeCssClass = useSelector((state) => state.appMenu.screenSize);
  const isEndDateValid = (date) => {
    const endDate = new Date(date);
    return endDate.getFullYear() >= 1900;
  };

  const RemoveDuplicateFlags = (warningFlags) => {
    const uniqueWarningFlagTypes = [
      ...new Set(
        warningFlags.map((flag) => GetKeyByValue(flag.warningFlagType, warningFlagTypeEnum)) // Extract warningflagtype values
      ),
    ];

    return uniqueWarningFlagTypes;
  };
  return (
    <Modal
      size={"xl"}
      dialogClassName={`custom-modal-dialog ${windowSizeCssClass}`}
      contentClassName={"custom-modal-content"}
      show={selectedResult}
      onHide={() => {
        dispatch(SetSelectedResult(null));
      }}
    >
      <div className={"bordered "}>
        <Modal.Header closeButton className={"pb-0 border-0"}>
          <Row>
            <Col xs={"auto"} className={" align-content-center"}>
              AssessmentSessions
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={"auto"} className={"text-color-gray-dark fw-medium"}>
              <ScrollableCard emptySpaceHeight={30}>
                {selectedResult?.sessions?.map((session, i) => {
                  const lastItem = i === selectedResult?.sessions?.length - 1;
                  return (
                    <div key={i} className={"p-0"}>
                      <Row className={"me-0"}>
                        <Col>
                          <Row>
                            <Col xs={"auto"}>
                              <div
                                className={"rounded-circle bg-color-accent"}
                                style={{ height: "24px", width: "24px" }}
                              >
                                <Row className={"justify-content-center h-100"}>
                                  <Col xs={"auto"} className={"align-content-center"}>
                                    <div
                                      className={"rounded-circle bg-white"}
                                      style={{ height: "18px", width: "18px" }}
                                    ></div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col>
                              {ParseDate(session.startDate).date} {ParseDate(session.startDate).time}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={"auto"}>
                              <Row className={"mx-0 justify-content-center"} style={{ width: "24px" }}>
                                <Col xs={"auto"} className={"px-0"}>
                                  <DottedLineUi type={"primary"} chainAmount={13} />
                                </Col>
                              </Row>
                            </Col>
                            <Col className={"align-content-center text-10 text-color-danger"}>
                              <Row className={"text-12 text-color-gray-light fw-medium "}>
                                {isEndDateValid(session.endDate) &&
                                  GetTimeDifference(session.startDate, session.endDate).hours +
                                    " Saat, " +
                                    GetTimeDifference(session.startDate, session.endDate).minutes +
                                    " Dakika"}
                              </Row>
                              {RemoveDuplicateFlags(session.warningFlags).map((warningFlag, i) => {
                                return <Row key={i}>{warningFlag}</Row>;
                              })}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={"auto"}>
                              <div
                                className={"rounded-circle bg-color-accent"}
                                style={{ height: "24px", width: "24px" }}
                              >
                                <Row className={"justify-content-center h-100"}>
                                  <Col xs={"auto"} className={"align-content-center"}>
                                    <div
                                      className={"rounded-circle bg-white"}
                                      style={{ height: "18px", width: "18px" }}
                                    ></div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col>
                              {isEndDateValid(session.endDate)
                                ? ParseDate(session.endDate).date + " " + ParseDate(session.endDate).time
                                : " invalid enddate"}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {!lastItem && (
                        <Row className={"me-0"}>
                          <Col xs={"auto"}>
                            <Row className={"mx-0 justify-content-center"} style={{ width: "24px" }}>
                              <Col xs={"auto"} className={"px-0"}>
                                <DottedLineUi type={"secondary"} chainAmount={5} />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </div>
                  );
                })}
              </ScrollableCard>
            </Col>
            <SessionImages></SessionImages>
          </Row>
        </Modal.Body>
      </div>
    </Modal>
  );
};
export default SessionsModal;
