import { createSlice } from "@reduxjs/toolkit";
import {
  getCompanyCredits,
  getCompanyStatistics,
  getFinishedApplicationsByDaysCount,
  getSentApplicationsByDaysCount,
} from "./Thunk";

export const adminDash = createSlice({
  name: "adminDash",
  initialState: {
    id: "",
    name: "",
    companyCredits: undefined,
    companyStatistics: null,
    statisticsModalIsShown: false,
    selectedNotification: {},
    finishedApplicationsByDaysCount: null,
    sentApplicationsByDaysCount: null,
    isLoading: false,
  },
  reducers: {
    SetStatisticsModalIsShown(state, action) {
      state.statisticsModalIsShown = action.payload;
    },
    SetSelectedNotification(state, action) {
      state.selectedNotification = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyCredits.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyCredits.fulfilled, (state, action) => {
      state.isLoading = false;
      state.companyCredits = action.payload?.credits;
    });
    builder.addCase(getCompanyStatistics.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyStatistics.fulfilled, (state, action) => {
      state.isLoading = false;
      state.companyStatistics = action.payload;
    });
    builder.addCase(getFinishedApplicationsByDaysCount.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getFinishedApplicationsByDaysCount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.finishedApplicationsByDaysCount = action.payload;
    });
    builder.addCase(getSentApplicationsByDaysCount.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSentApplicationsByDaysCount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.sentApplicationsByDaysCount = action.payload;
    });
  },
});

export const { SetStatisticsModalIsShown, SetSelectedNotification } = adminDash.actions;

export default adminDash.reducer;
