export const ExitFullscreen = () => {
  if (document.fullscreenElement) {
    if (document.exitFullscreen) {
      document.exitFullscreen().catch(() => alert("Lütfen F11'e basın"));
    }
  } else {
    console.error("No element is currently in fullscreen mode.");
  }
};
export const EnterFullscreen = () => {
  const element = document.documentElement; // Default to the document if no element is specified
  if (element.requestFullscreen) {
    element.requestFullscreen().catch(() => alert("Lütfen f11 e basın"));
  } else {
    console.error("Fullscreen API is not supported by this browser.");
  }
};
