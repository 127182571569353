import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { deleteComment, upsertComment } from "../Store/Thunk";
import { ChangeCommentContent, ClearSelectedComment } from "../Store";
import Col from "react-bootstrap/Col";
import IconSvg from "../../../AppMenu/Components/IconSvg";
import Row from "react-bootstrap/Row";

const UpsertCandidateModal = ({ positionList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedComment = useSelector((state) => state.candidate.selectedComment);
  const companyUserId = useSelector((state) => state.auth.user.companyUser.companyUserId);
  const companyUsername = useSelector((state) => state.auth.user.companyUser.companyUsername);
  const candidate = useSelector((state) => state.candidate.candidate);
  const isUpdateOperation = useSelector((state) => state.candidate.isUpdateOperation);
  const windowSizeCssClass = useSelector((state) => state.appMenu.screenSize);

  const [commentInputText, setCommentInputText] = useState("");

  const HandleSubmit = (e) => {
    e.preventDefault();
    if (selectedComment.commentId) {
      dispatch(
        upsertComment({
          content: selectedComment.content,
          commentId: selectedComment.commentId,
        })
      );
    } else {
      dispatch(
        upsertComment({
          content: selectedComment.content,
          companyUserId: companyUserId,
          candidateId: candidate.candidateId,
        })
      );
    }
    setCommentInputText("");
    dispatch(ClearSelectedComment());
  };

  return (
    <Modal
      size={"lg"}
      dialogClassName={`custom-modal-dialog ${windowSizeCssClass}`}
      contentClassName={"custom-modal-content"}
      show={selectedComment}
      onHide={() => {
        setCommentInputText("");
        dispatch(ClearSelectedComment());
      }}
    >
      <div className={"bordered"}>
        <Form onSubmit={HandleSubmit}>
          <Modal.Header closeButton className={"p-0 border-bottom-0 text-18 text-color-gray-light fw-medium"}>
            <Row>
              <Col xs={"auto"}>
                <IconSvg icon={"comment"}></IconSvg>
              </Col>
              <Col xs={"auto"}>{companyUsername}</Col>
            </Row>
          </Modal.Header>
          <Modal.Body>
            <Form.Label column={"sm"} className={"text-20 text-color-gray-light fw-medium"}>
              Yorum
            </Form.Label>
            <Form.Control
              className={"default-text-input"}
              style={{ height: "50px" }}
              onChange={(e) => dispatch(ChangeCommentContent(e.target.value))}
              value={selectedComment?.content}
              placeholder={t("enterCommentPlaceholder")}
            ></Form.Control>
          </Modal.Body>
          <Modal.Footer className={"justify-content-evenly"}>
            {selectedComment?.commentId && (
              <Col xs={"auto"}>
                <Button
                  style={{ height: "50px", width: "200px" }}
                  className={"button secondary danger"}
                  onClick={() => {
                    dispatch(deleteComment({ id: selectedComment.commentId }));
                    dispatch(ClearSelectedComment());
                  }}
                >
                  DELETE
                </Button>
              </Col>
            )}
            <Col xs={"auto"}>
              <Button type={"submit"} className={"button primary"} style={{ height: "50px", width: "200px" }}>
                {selectedComment?.commentId ? "UPDATE" : "CREATE"}
              </Button>
            </Col>
          </Modal.Footer>
        </Form>
      </div>
    </Modal>
  );
};
export default UpsertCandidateModal;
