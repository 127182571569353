export const AdminDashApiUrls = {
  getCompanyCredits: "dash/GetCompanyCredits",
  getCompanyStatistics: "dash/GetCompanyStatistics",
  getFinishedApplicationsByDaysCount: "dash/GetFinishedApplicationsByDaysCount",
  getSentApplicationsByDaysCount: "dash/GetSentApplicationsByDaysCount",
};

export default class adminDashAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async getCompanyCredits(data, token) {
    return this.get(AdminDashApiUrls.getCompanyCredits, data, token);
  }

  async getCompanyStatistics(data, token) {
    return this.get(AdminDashApiUrls.getCompanyStatistics, data, token);
  }

  async getSentApplicationsByDaysCount(data, token) {
    return this.get(AdminDashApiUrls.getSentApplicationsByDaysCount, data, token);
  }

  async getFinishedApplicationsByDaysCount(data, token) {
    return this.get(AdminDashApiUrls.getFinishedApplicationsByDaysCount, data, token);
  }

  async get(url, data, token, eAlert, sAlert) {
    return await this.httpsClient.get(url, data, token, eAlert, sAlert);
  }

  async post(url, data, token, eAlert, sAlert) {
    this.cache.remove("tests");

    return await this.httpsClient.post(url, data, token, eAlert, sAlert);
  }
}
