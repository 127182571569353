import Modal from "react-bootstrap/Modal";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { finishSession } from "../../AnswerInventoryApp/Store/Thunk";
import { ProgressBar } from "react-bootstrap";
import { ExitFullscreen } from "../../../../Helpers/FullscreenHelper";

const CautionModal = ({ isShown, warningObject, handleDismiss, extraScreens }) => {
  const navigate = useNavigate();
  const sessionId = useSelector((state) => state.answeringApplication.sessionId);
  const dispatch = useDispatch();
  const [remainingTime, setRemainingTime] = useState(0);

  const isRunning = useRef(false);
  const intervalRef = useRef(null); // Reference to track the timeout
  const loadingBarMax = useRef(0);
  const loadingBarCurrent = useRef(0);

  useEffect(() => {
    if (isShown) {
      if (extraScreens) {
        HandleTerminationTimer(20000);
      } else {
        HandleTerminationTimer(10000);
      }
    } else {
      StopTerminationTimer();
    }
  }, [isShown]);

  const FinishSession = () => {
    dispatch(finishSession({ id: sessionId }));
    navigate("/userDash");
    ExitFullscreen();
  };

  const HandleTerminationTimer = (totalDurationInMs) => {
    StopTerminationTimer(); // Clear previous timer and reset state
    loadingBarMax.current = totalDurationInMs;
    isRunning.current = true; // Start the timer
    const startTime = new Date();

    // Start interval
    intervalRef.current = setInterval(() => {
      const elapsed = new Date() - startTime; // Time since the timer started
      const remainingTime = Math.max(0, totalDurationInMs - elapsed); // Prevent negative time

      loadingBarCurrent.current = remainingTime;

      setRemainingTime(remainingTime / 1000); // Update remaining time
      // Update progress bar

      if (remainingTime <= 0) {
        clearInterval(intervalRef.current); // Clear interval
        FinishSession();
        alert("Timeout!");
        isRunning.current = false; // Ensure the timer is stopped
        console.log("Termination logic executed.");
      }
    }, 100); // Update every 100ms
  };

  const StopTerminationTimer = () => {
    isRunning.current = false; // Stop the timer
    if (intervalRef.current) {
      clearTimeout(intervalRef.current); // Clear any scheduled timeouts
    }
    console.log("Timer manually stopped.");
  };

  return (
    <Modal
      size={"lg"}
      dialogClassName={"custom-modal-dialog"}
      contentClassName={"custom-modal-content"}
      backdropClassName={"custom-modal-backdrop"}
      show={isShown}
    >
      <div className={"bordered"}>
        <Row className={"mb-3"}>
          <Col>
            <ProgressBar
              className={"custom-progress-bar"}
              max={loadingBarMax.current}
              now={loadingBarCurrent.current}
              style={{ height: "30px" }}
            ></ProgressBar>
          </Col>
        </Row>
        <Row className={"justify-content-center "}>
          <Col xs={"auto"} className={"fw-bold text-30"}>
            {remainingTime.toFixed(1)}
          </Col>
        </Row>
        <Row className={"justify-content-center"}>
          <Col xs={"auto"} className={"fw-semibold"}>
            ERROR
            {warningObject.fullscreen && " fullscreen"}
            {warningObject.tabSwitch && " tabSwitch"}
            {warningObject.mouseOut && " mouseOut"}
            {warningObject.pageVisibility && " pageVisibility"}
            {warningObject.pageBlur && " pageBlur"}
            {warningObject.extraScreen && " extraScreen"}
          </Col>
        </Row>

        <Row className={"justify-content-between"}>
          <Col xs={"auto"}>
            <Button
              className={"button secondary"}
              disabled={extraScreens}
              onClick={() => {
                handleDismiss(false);
                StopTerminationTimer();
              }}
            >
              {extraScreens ? "detach your extra screen" : "Devam et"}
            </Button>
          </Col>
          <Col xs={"auto"}>
            <Button
              className={"button secondary"}
              onClick={() => {
                StopTerminationTimer();
                FinishSession();
              }}
            >
              Çıkış
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default CautionModal;
