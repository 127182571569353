import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ProjectList from "../ProjectList";
import React, { useEffect } from "react";
import { getCompanyCreateProjectData, getLastTenOrderRecords } from "../../Store/Thunk";
import Creatable from "react-select/creatable";
import Select from "react-select";
import validator from "validator";

import {
  ChangeCameraRequirement,
  ChangeProjectSubmitModalIsShown,
  ChangeResultAccess,
  ClearDisabledSelectors,
  PushToProjectList,
  SetSelectedAssessment,
  SetSelectedOptionByCandidateId,
  SetSelectedPosition,
} from "../../Store";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ProjectSubmitModal from "../ProjectSubmitModal";
import { reactSelectStyles } from "../../../../SharedComponents/reactSelectStyles";
import IconSvg from "../../../../AppMenu/Components/IconSvg";

const CreateProject = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectList = useSelector((state) => state.projects.projectList);
  const resultAccess = useSelector((state) => state.projects.resultAccess);
  const cameraRequirement = useSelector((state) => state.projects.cameraRequirement);
  const reactSelectOptions = useSelector((state) => state.projects.reactSelectOptions);
  const selectedOption = useSelector((state) => state.projects.selectedOption);

  useEffect(() => {
    dispatch(getCompanyCreateProjectData());
    dispatch(getLastTenOrderRecords());
  }, []);

  const isAnythingDisabled =
    selectedOption.selectedNameOption?.isDisabled ||
    selectedOption.selectedEmailOption?.isDisabled ||
    selectedOption.selectedPositionOption?.isDisabled ||
    selectedOption.selectedAssessmentOption?.isDisabled;

  return (
    <>
      <ProjectSubmitModal></ProjectSubmitModal>
      <Row className={"mx-0 mb-3 justify-content-between"}>
        <Col>
          <Creatable
            placeholder={"Aday adı"}
            styles={reactSelectStyles}
            options={reactSelectOptions.nameOptions}
            value={selectedOption.selectedNameOption}
            onChange={(selected) => {
              dispatch(SetSelectedOptionByCandidateId({ ...selected, type: "name" }));
            }}
            isDisabled={selectedOption.selectedNameOption?.isDisabled}
            isValidNewOption={() => true}
          ></Creatable>
        </Col>
        <Col>
          <Creatable
            placeholder={"Aday E-postası"}
            styles={reactSelectStyles}
            options={reactSelectOptions.emailOptions}
            value={selectedOption.selectedEmailOption}
            onChange={(selected) => {
              dispatch(SetSelectedOptionByCandidateId({ ...selected, type: "email" }));
            }}
            isDisabled={selectedOption.selectedEmailOption?.isDisabled}
            onCreateOption={(inputValue) => {
              // Validate if the input is a valid email
              if (validator.isEmail(inputValue)) {
                // Add the new option
                const newOption = { value: inputValue, label: inputValue, __isNew__: true };
                dispatch(SetSelectedOptionByCandidateId({ ...newOption, type: "email" }));
              } else {
                alert("Invalid email address. Please enter a valid email.");
              }
            }}
          ></Creatable>
        </Col>
        <Col>
          <Select
            placeholder={"Pozisyon"}
            styles={reactSelectStyles}
            options={reactSelectOptions.positionOptions}
            value={selectedOption.selectedPositionOption}
            isDisabled={selectedOption.selectedPositionOption?.isDisabled}
            onChange={(selected) => {
              dispatch(SetSelectedPosition(selected));
            }}
          ></Select>
        </Col>
        <Col>
          <Select
            placeholder={"Değerleme"}
            styles={reactSelectStyles}
            options={reactSelectOptions.assessmentOptions}
            isDisabled={selectedOption.selectedAssessmentOption?.isDisabled}
            value={selectedOption.selectedAssessmentOption?.array}
            onChange={(selected) => {
              dispatch(SetSelectedAssessment(selected));
            }}
            isMulti
          ></Select>
        </Col>
        {isAnythingDisabled && (
          <Col xs={"auto"}>
            <Button
              className={"button secondary h-100"}
              onClick={() => {
                dispatch(ClearDisabledSelectors());
              }}
            >
              <IconSvg icon={"sweepV2"} fill={"#0077b6"} height={30} width={30}></IconSvg>
            </Button>
          </Col>
        )}

        <Col xs={"auto"}>
          <Button
            className={"button secondary h-100"}
            disabled={
              selectedOption.selectedAssessmentOption?.array?.length === 0 ||
              !selectedOption.selectedNameOption?.value ||
              !selectedOption.selectedEmailOption?.value
            }
            onClick={() => {
              dispatch(PushToProjectList());
            }}
          >
            add
          </Button>
        </Col>
      </Row>
      <Row className={"mx-0"}>
        <Col>
          <ProjectList></ProjectList>
        </Col>
      </Row>
      <div
        className={"position-absolute w-100"}
        style={{ bottom: "0", marginLeft: "-37px", marginRight: "-30px", backgroundColor: "#f8f9fa" }}
      >
        <Row className={"mx-0 justify-content-end"} style={{ height: "80px" }}>
          <Col xs={"auto"} className={"align-content-center"}>
            <Row>
              <Col>Result Access</Col>
              <Col xs={"auto"}>
                <Form.Check type={"switch"} onChange={() => dispatch(ChangeResultAccess())} checked={resultAccess} />
              </Col>
            </Row>
          </Col>

          <Col xs={"auto"} className={"align-content-center"}>
            <Row>
              <Col>Camera Requirement</Col>
              <Col xs={"auto"}>
                <Form.Check
                  type={"switch"}
                  onChange={() => dispatch(ChangeCameraRequirement())}
                  checked={cameraRequirement}
                />
              </Col>
            </Row>
          </Col>

          <Col xs={"auto"} className={"align-content-center"}>
            <Button
              className={"button secondary"}
              disabled={projectList.length === 0}
              onClick={() => {
                dispatch(ChangeProjectSubmitModalIsShown(true));
              }}
            >
              Send
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default CreateProject;
