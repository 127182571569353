import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ApiHelper from "../../../Helpers/ApiHelper";

import { QuestionSlider } from "./Questions/QuestionSlider";
import { CandidateInfo } from "../SharedInfoPages/CandidateInfo";
import { ApplicationInfo } from "../SharedInfoPages/ApplicationInfo";
import Overlay from "../../SharedComponents/Overlay";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";
import AnsweringPageWrapper from "../SharedComponents/AnsweringPageWrapper";

const MySwal = withReactContent(Swal);

export function DemoTest(props) {
  const { ticketId } = useParams();
  const { testId } = useParams();

  const user = useSelector((state) => state.auth.user);

  const [ticket, setTicket] = useState(null);
  const navigate = useNavigate();

  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const [applicationData, setApplicationData] = useState();
  const [isInfoGot, setIsInfoGot] = useState(ticketId ? true : false);
  const [isInfoShowed, setIsInfoShowed] = useState();

  const [postData, setPostData] = useState({
    demoResultId: null,
    info: {},
    questionAnswersJson: false,
  });

  const [finishedTestData, setFinishedTestData] = useState(false);

  useEffect(() => {
    document.getElementById("login")?.remove();
    if (testId || ticketId) {
      GetTestImageForB2c();
      window.isFinished = false;
    } else {
      setIsOverlayVisible(false);
      MySwal.fire({
        title: <strong>Hata</strong>,
        html: <i>Bozuk Link</i>,
        icon: "error",
      });
      return;
    }
  }, []);

  let GetTestImageForB2c = () => {
    ApiHelper.getAsync(
      "user/GetTestImage",
      { testId: testId },
      function (result) {
        setIsOverlayVisible(false);
        if (result.data.statusCode === 1) {
          HandlequestionStatesAndOpenApplication(result.data.data);
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>Bozuk Link</i>,
            icon: "error",
          });
        }
      },
      null
    );
  };

  let HandlequestionStatesAndOpenApplication = (test) => {
    const questions = JSON.parse(test.questionsImage.questionsJson);

    setApplicationData({
      questions,
      testId: test.id,
      duration: test.duration,
      info: test.info,
    });
  };

  let FinishCandidateInfo = (info) => {
    setIsOverlayVisible(true);
    info.testId = applicationData.testId;
    ApiHelper.postAsync(
      "user/CreateDemoResult",
      info,
      function (result) {
        setIsOverlayVisible(false);
        if (result.data.statusCode === 1) {
          setIsInfoGot(true);
          setPostData((pd) => ({
            ...pd,
            info,
            testId: applicationData.testId,
            demoResultId: result.data.data.id,
          }));
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>Bir hata oluştu</i>,
            icon: "error",
          });
        }
      },
      null
    );
  };

  let CreateTicketResut = () => {
    setIsOverlayVisible(true);
    ApiHelper.postAsync(
      "ticket/CreateTicketResult",
      {
        ticketId: ticketId,
        questionAnswersJson: JSON.stringify(applicationData.questions),
        remainingDuration: applicationData.duration * 60,
      },
      function (result) {
        setIsOverlayVisible(false);
        if (result.data.statusCode === 1) {
          setTicket({ ...ticket, ticketResult: result.data.data });
          setIsInfoShowed(true);
        } else {
          //todo kayi alinmazsa modal goster
        }
      },
      user.token
    );
  };

  let FinishInfo = () => {
    if (props.b2b && !ticket?.ticketResult && ticket?.clickCounter <= 1) CreateTicketResut();
    else setIsInfoShowed(true);
  };

  let DismissFinish = (questionAnswersJson) => {
    setPostData((pd) => ({ ...pd, questionAnswersJson }));
  };

  let FinishTest = (questionAnswersJson) => {
    PostFinishedTest(questionAnswersJson);
  };

  let UpdateQuestionAnswersJsonB2b = async (questionAnswersJson) => {
    ApiHelper.postAsync(
      "ticket/UpdateTicketResultQuestionAnswersJson",
      {
        id: ticket?.ticketResult.id,
        questionAnswersJson: JSON.stringify(questionAnswersJson),
      },
      function (result) {},
      user.token
    );
  };

  let UpdateDurationAndPhoto = async (duration, photo) => {
    const formData = new FormData();

    formData.append("ticketId", ticket?.ticketResult.id);
    formData.append("duration", duration);
    photo && formData.append("photo", photo);

    ApiHelper.xmlPostFormData("ticket/UpdateDurationAndPhoto", formData, user.token);
  };
  // const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  let PostFinishedTest = async (questionAnswersJson) => {
    setIsOverlayVisible(true);

    let finishingTestPostObj = {
      id: postData.demoResultId,
      questionAnswersJson: JSON.stringify(questionAnswersJson),
      isFinished: true,
      candidateEmail: user?.userName,
      candidateName: user?.demography?.name,
    };
    ApiHelper.postAsync(
      "user/UpdateDemoResult",
      finishingTestPostObj,
      function (result) {
        setIsOverlayVisible(false);
        if (result.data.statusCode === 1) {
          setFinishedTestData(result.data.data);
          result.data.data.testId = applicationData.testId;
          navigate("/tdemoResult/" + finishingTestPostObj.id, { state: result.data.data });
        } else {
          MySwal.fire({
            title: <strong>Hata</strong>,
            html: <i>Bir problem olustu: {result.data.data} </i>,
            icon: "error",
          });
        }
      },
      null
    );
  };

  let HandleFinishButton = () => {
    PostFinishedTest(postData.questionAnswersJson);
  };

  let HandleShowThanks = () => {
    if (finishedTestData && (!props.b2c || !ticket?.allowPdf)) return true;
    else return false;
  };

  return (
    <AnsweringPageWrapper>
      {isOverlayVisible && <Overlay />}
      {applicationData && applicationData.testId !== null && applicationData.testId !== "" && !isInfoGot && (
        <CandidateInfo Finish={FinishCandidateInfo} />
      )}
      {applicationData &&
        applicationData.testId !== null &&
        applicationData.testId !== "" &&
        isInfoGot &&
        !isInfoShowed && (
          <ApplicationInfo Finish={FinishInfo} clickCounter={ticket?.clickCounter} info={applicationData.info} />
        )}

      {!finishedTestData && isInfoGot && isInfoShowed && applicationData && applicationData.questions && (
        <QuestionSlider
          isB2b={props.b2b}
          questions={applicationData.questions}
          duration={applicationData.duration}
          openCam={ticket?.openCam}
          finish={FinishTest}
          dismissFinish={DismissFinish}
          Update={UpdateQuestionAnswersJsonB2b}
          UpdateDurationAndPhoto={UpdateDurationAndPhoto}
          isFinished={!finishedTestData && postData.questionAnswersJson}
          HandleFinishButton={HandleFinishButton}
        />
      )}

      {HandleShowThanks() && <h1 className="thank-message"> Katılımınız için teşekkürler </h1>}
      {window.innerWidth <= 500 && <div style={{ height: "200px", width: "100%" }}></div>}
    </AnsweringPageWrapper>
  );
}
