import { Button, Col, Row } from "react-bootstrap";
import React from "react";
import { ParseDate } from "../../../../../Helpers/DateHelper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { applicationTypeEnum, GetKeyByValue } from "../../../../../Helpers/EnumHelper";

const NotificationListRow = ({ notification }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const applicationType = GetKeyByValue(notification.applicationType, applicationTypeEnum);

  const HandleGoToResult = () => {
    switch (applicationType) {
      case "Inventory": {
        navigate("/iwrapup/" + notification.applicationResultId);
        break;
      }
      case "Test": {
        navigate("/twrapup/" + notification.applicationResultId);
        break;
      }
      case "Survey": {
        break;
      }
      default: {
        console.log("something wrong with HandleGoToResult at NotificationModal");
      }
    }
  };

  const HandleGoToCandidate = () => {
    navigate("/candidate", { state: { candidateId: notification.candidateId, backUrl: "/dash" } });
  };

  return (
    <div className={"p-0 mb-3 hover-bg-darken"}>
      <Row className={" text-20 text-color-gray-dark fw-medium"} style={{ height: "50px" }}>
        <Col xs={{ span: 4, offset: 0 }} className={"align-content-center"}>
          <Row>
            <Col xs={3} className={"align-content-center"}>
              <Row className={"justify-content-end"}>
                <Col xs={"auto"}>
                  <div
                    className={"rounded-circle align-content-center border border-1 border-gray"}
                    style={{ height: "40px", width: "40px" }}
                  ></div>
                </Col>
              </Row>
            </Col>
            <Col xs={9} className={"align-content-center"}>
              <div className="hover-container h-100 w-100 p-0 position-relative">
                {/*  Candidate Name (Visible by Default) */}
                <div className="position-absolute top-0 h-100 w-100 hover-actions-reversed">
                  <Row className={"h-100"}>
                    <Col xs={"auto"} className={"align-content-center"}>
                      {notification?.candidateName}
                    </Col>
                  </Row>
                </div>

                {/* Buttons (Initially Hidden, Show on Hover) */}
                <div className="position-absolute top-0 h-100 w-100 hover-actions">
                  <Row className={"h-100"}>
                    <Col xs={"auto"} className={"align-content-center"}>
                      <Button
                        size={"sm"}
                        className={"button secondary"}
                        style={{ borderRadius: "10px" }}
                        onClick={() => {
                          HandleGoToCandidate();
                        }}
                      >
                        Adaya git
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={4} className="align-content-center text-16">
          <div className="hover-container h-100 w-100 p-0 position-relative">
            {/* Application Name (Visible by Default) */}
            <div className="position-absolute top-0 h-100 w-100 hover-actions-reversed">
              <Row className={"justify-content-center  h-100"}>
                <Col xs={"auto"} className={"align-content-center"}>
                  {notification?.applicationName}'i Tamamladı
                </Col>
              </Row>
            </div>

            {/* Buttons (Initially Hidden, Show on Hover) */}
            <div className="position-absolute top-0 h-100 w-100 hover-actions">
              <Row className={"justify-content-center  h-100"}>
                <Col xs={"auto"} className={"align-content-center"}>
                  <Button
                    size={"sm"}
                    className={"button secondary"}
                    style={{ borderRadius: "10px" }}
                    onClick={() => {
                      HandleGoToResult();
                    }}
                  >
                    Sonuca git
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <Col xs={{ span: 3, offset: 1 }} className={"align-content-center"}>
          <Row className={"mx-0 text-18 text-color-gray-light fw-medium"}>
            {ParseDate(notification?.finishDate).date} {ParseDate(notification?.finishDate).time}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default NotificationListRow;
