import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React from "react";

const DemographicInfoColumn = ({ candidate }) => {
  return (
    <Row className={"mx-0 justify-content-evenly"} style={{ minWidth: "600px" }}>
      <Col xs={6} className={" fw-medium"}>
        <Row style={{ height: "27px" }}>
          <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
            Telefon:
          </Col>
          <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark  "}>
            {candidate?.phoneNumber}
          </Col>
        </Row>
        <Row style={{ height: "27px" }}>
          <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
            Pozisyon:
          </Col>
          <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark  "}>
            {candidate?.positionName}
          </Col>
        </Row>

        <Row style={{ height: "27px" }}>
          <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
            Cinsiyet:
          </Col>
          <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark  "}>
            {candidate?.gender}
          </Col>
        </Row>

        <Row style={{ height: "27px" }}>
          <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
            Eğitim:
          </Col>
          <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark "}>
            {candidate?.school}
          </Col>
        </Row>
      </Col>
      <Col xs={6} className={" fw-medium"}>
        <Row style={{ height: "27px" }}>
          <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
            Doğum tarihi:
          </Col>
          <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark "}>
            {candidate?.birthYear}
          </Col>
        </Row>

        <Row style={{ height: "27px" }}>
          <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
            Tecrübe:
          </Col>
          <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark"}>
            {candidate?.experience}
          </Col>
        </Row>

        <Row style={{ height: "27px" }}>
          <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
            Fakülte:
          </Col>
          <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark  "}>
            {candidate?.faculty}
          </Col>
        </Row>

        <Row style={{ height: "27px" }}>
          <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
            Departman:
          </Col>
          <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark "}>
            {candidate?.department}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default DemographicInfoColumn;
