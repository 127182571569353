import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import IconSvg from "../../../AppMenu/Components/IconSvg";
import { ChangeIsUpdateOperation, SetUpsertModalCandidate, ShowUpsertCandidateModal } from "../Store";
import { SetSelectedOptionByCandidateId } from "../../Project/Store";
import { SetSelectedPage } from "../../../AppMenu/Store";
import PerfectScrollbar from "react-perfect-scrollbar";

const CreateCandidateListRow = ({ candidate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const positionList = useSelector((state) => state.createPosition.positionList);
  const [carouselItems, setCarouselItems] = useState([]);

  useEffect(() => {
    const carouselBlockArray = candidate.tickets.map((ticket) => ticket.assessmentName);

    setCarouselItems(groupIntoBundles(carouselBlockArray, 3));
  }, []);

  const FindPositionNameByPositionId = (positionId) => {
    return positionList.find((position) => position.id === positionId)?.name;
  };

  const groupIntoBundles = (items, bundleSize) => {
    const result = [];
    let bundle = [];
    items.forEach((item, index) => {
      bundle.push(item);
      if ((index + 1) % bundleSize === 0 || index === items.length - 1) {
        result.push(bundle);
        bundle = [];
      }
    });
    return result;
  };

  const handleUpdateCandidate = () => {
    dispatch(SetUpsertModalCandidate(candidate));

    dispatch(ShowUpsertCandidateModal({ upsertCandidateModalIsShown: true }));
    dispatch(ChangeIsUpdateOperation({ isUpdateOperation: true }));
  };

  return (
    <Row className={"h-100"}>
      <Col xs={"auto"} className="align-content-center ms-5">
        <Row className={"justify-content-center"}>
          <div
            style={{
              border: "1px solid #E7ECEF",
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              alignContent: "center",
            }}
          >
            <Row className={"justify-content-center"}>
              <IconSvg icon={"candidate"} />
            </Row>
          </div>
        </Row>
      </Col>
      <Col
        xs={3}
        className={"position-relative align-content-center ms-5 my-3 border-end"}
        style={{ borderColor: "#E7ECEF" }}
      >
        <Row
          className={"hover-bg-darken h-100"}
          onClick={() => {
            navigate("/candidate", { state: { candidateId: candidate.candidateId, backUrl: "/createCandidate" } });
          }}
        >
          <Col className={"align-content-center "}>
            <Row>
              <Col xs={"auto"} className={"text-20 text-color-accent fw-semibold"}>
                {candidate.candidateName}
              </Col>
            </Row>
            <Row className={"text-16 text-color-gray-light fw-medium"}>
              <Col xs={"auto"}>{candidate.email}</Col>
            </Row>
            <Row className={"text-16 text-color-gray-light fw-medium"}>
              <Col xs={"auto"}>{FindPositionNameByPositionId(candidate.positionId)}</Col>
            </Row>
          </Col>
        </Row>

        <div
          className=" hover-bg-darken p-1 absolute-top-right"
          onClick={() => {
            handleUpdateCandidate();
          }}
        >
          <IconSvg icon="edit" />
        </div>
      </Col>
      <Col className={"mt-3  overflow-hidden"} style={{ borderColor: "#E7ECEF" }}>
        <PerfectScrollbar
          options={{ suppressScrollY: true }} // Disable vertical scrolling
          style={{ maxWidth: "100%", overflowX: "auto", paddingBottom: "15px" }}
        >
          <div
            className={"h-100"}
            style={{
              display: "flex", // Use Flexbox for horizontal layout
              gap: "25px", // Spacing between flex items
              padding: "12px 0",
            }}
          >
            {candidate.tickets.map((ticket, index) => (
              <div
                key={index}
                className={"h-100 p-3"}
                style={{
                  borderRadius: "20px",
                  width: "210px",
                  minWidth: "210px",
                  border: "solid 2px #0077b6",
                }}
              >
                <Row className={"justify-content-center h-100"}>
                  <Col
                    xs={"auto"}
                    className={"text-center align-content-center text-16 text-color-gray-dark fw-medium"}
                  >
                    {ticket.assessmentName}
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </PerfectScrollbar>
      </Col>
      <Col xs={"auto"} className={"my-3 border-end"} style={{ borderColor: "#E7ECEF", padding: "0px" }}></Col>
      <Col xs={"auto"} className={"align-content-center me-5 ms-4"}>
        <Row className={"justify-content-between"}>
          <Col xs={"auto"} className={"align-content-center"}>
            <Row className={"justify-content-center"}>
              <Button
                className={"button secondary"}
                onClick={() => {
                  dispatch(SetSelectedOptionByCandidateId({ value: candidate.candidateId }));
                  navigate("/project", { state: { backUrl: "/createCandidate/" } });
                  dispatch(SetSelectedPage({ url: "/project/" }));
                }}
              >
                Değerleme Gönder
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default CreateCandidateListRow;
