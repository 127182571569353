import { Col, Row } from "react-bootstrap";
import IconSvg from "../../../../AppMenu/Components/IconSvg";

const StatisticTimePeriod = ({ color, headerText, completedCount, assignedCount }) => {
  return (
    <Row>
      <Col>
        <Row className={"mb-2"}>
          <Col>
            <Row className={"justify-content-end h-100"} style={{ marginRight: "-24px" }}>
              <Col xs={"auto"} className={"px-0 align-content-center pb-1"}>
                <IconSvg icon={"succes-dot"} fill={color}></IconSvg>
              </Col>
            </Row>
          </Col>
          <Col
            xs={"auto"}
            style={{ borderBottom: "2px solid #E7ECEF", padding: "0 24px" }}
            className={"text-18 text-color-gray-light fw-medium align-content-center text-center"}
          >
            {headerText}
          </Col>
          <Col></Col>
        </Row>
        <Row className={"justify-content-center mb-1"}>
          <Col xs={"auto"} className={"text-14 text-color-gray-light fw-medium text-center"}>
            {assignedCount} Uygulama gönderildi
          </Col>
        </Row>
        <Row className={"justify-content-center mb-2"}>
          <Col xs={"auto"} className={"text-16 text-color-gray-dark fw-medium text-center"}>
            {completedCount} Uygulama tamamlandı
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default StatisticTimePeriod;
