import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import "animate.css";
import Overlay from "../../../SharedComponents/Overlay";

export function Counter(props) {
  const [remainingSecond, setRemainingSecond] = useState(props.duration * 60);
  const [counterCssClass, setCounterCssClass] = useState("counter-container");
  const [showPermissionOverlay, setShowPermissionOverlay] = useState(true);
  const intervalRef = useRef(null);
  const startDateRef = useRef(new Date());
  const webcamRef = useRef(null);

  // ✅ Function to check camera permissions
  const CheckPermission = async () => {
    if (!props.isB2b) return true;
    return navigator.permissions.query({ name: "camera" }).then((permissionObj) => {
      if (permissionObj.state === "denied" || permissionObj.state === "prompt") {
        setShowPermissionOverlay(true);
        return false;
      } else {
        setShowPermissionOverlay(false);
        return true;
      }
    });
  };

  // ✅ Function to handle the remaining time countdown
  const HandleRemainingTime = async () => {
    if (props.stopCounter) {
      clearInterval(intervalRef.current);
      return;
    }

    if (props.isB2b && props.openCam) {
      const permission = await CheckPermission();
      if (!permission) return;
    }

    const now = new Date();
    const secondsPassed = Math.floor((now - startDateRef.current) / 1000);
    const remaining = props.duration * 60 - secondsPassed;

    if (remaining <= 0) {
      clearInterval(intervalRef.current);
      setRemainingSecond(0);
      props.Finish();
      return;
    }

    if (props.isB2b && props.openCam && remaining % 15 === 0) {
      const screenshot = webcamRef.current?.getScreenshot();
      props.Update(remaining, screenshot ? getFileFromBase64(screenshot) : null);
    }

    if (remaining === 10) {
      setCounterCssClass("counter-container-danger animate__animated animate__flash animate__infinite infinite");
    }

    setRemainingSecond(remaining);
  };

  // ✅ useEffect to start the countdown timer when the component mounts
  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(HandleRemainingTime, 1000);
    }

    return () => clearInterval(intervalRef.current); // Cleanup interval when unmounting
  }, []); // Empty dependency array ensures it only runs on mount

  // ✅ useEffect to stop the timer when `props.stopCounter` is true
  useEffect(() => {
    if (props.stopCounter) {
      clearInterval(intervalRef.current);
    }
  }, [props.stopCounter]);

  // ✅ Function to convert base64 to a file
  function getFileFromBase64(string64) {
    if (!string64) return null; // Prevent errors if the screenshot is null

    const fileName = new Date().getTime() + ".jpeg";
    const trimmedString = string64.replace("data:image/jpeg;base64,", "");
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }

    const type = "image/jpeg";
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type,
    });
  }

  // ✅ Get formatted minutes and seconds
  const remainingMinute = Math.floor(remainingSecond / 60);
  const remainingSecond_ = remainingSecond % 60;

  return (
    <>
      {props.openCam && props.isB2b && showPermissionOverlay && (
        <Overlay magnify={true} text="Devam edebilmek için kamera erişim izni verin lütfen" />
      )}
      <div className={counterCssClass}>
        {props.isB2b && props.openCam && (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{ width: 640, height: 480, facingMode: "user" }}
            minScreenshotWidth={180}
            minScreenshotHeight={180}
            style={{ width: "90px", height: "90px", position: "absolute", left: "-100px", top: "-10px" }}
          />
        )}
        <span className="red-dot"></span>
        <h1 className="timer-second-minute-container">
          {remainingMinute < 10 ? "0" : ""}
          {remainingMinute}
        </h1>
        <h1> :</h1>
        <h1 className="timer-second-minute-container">
          {remainingSecond_ < 10 ? "0" : ""}
          {remainingSecond_}
        </h1>
      </div>
    </>
  );
}
