import AlertItem from "./AlertItem";
import { useSelector } from "react-redux";

const AlertWrapper = () => {
  const alertArray = useSelector((state) => state.alerts.alertArray);

  console.log("re-rendered alert wrapper");
  return (
    <div className={"alert-wrapper overflow-x-hidden"}>
      {alertArray.map((alert, index) => (
        <AlertItem id={alert.id} text={alert.text} type={alert.type} key={alert.id || index} />
      ))}
    </div>
  );
};
export default AlertWrapper;
