import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import IconSvg from "../../../AppMenu/Components/IconSvg";
import Button from "react-bootstrap/Button";
import Comment from "./CommentSection/Comment";
import { getCandidateById } from "../Store/Thunk";
import { SetSelectedComment } from "../Store";
import AdminBreadCrumb from "../../AdminBreadCrumb";
import UpsertCommentModal from "./UpsertCommentModal";
import { SetSelectedOptionByCandidateId } from "../../Project/Store";
import {
  ChangeIsUpdateOperation,
  SetUpsertModalCandidate,
  ShowUpsertCandidateModal,
} from "../../CreateCandidate/Store";
import UpsertCandidateModal from "../../CreateCandidate/Components/UpsertCandidateModal";
import DemographicInfoColumn from "./DemographicInfoColumn";
import { SetSelectedPage } from "../../../AppMenu/Store";
import { getCompanyCreateProjectData } from "../../Project/Store/Thunk";
import AssignedAssessments from "./AssessmentsSection/AssignedAssessments";
import SessionsModal from "./AssessmentsSection/Modals/SessionsModal";
import Overlay from "../../../SharedComponents/Overlay";

const Candidate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.candidate.isLoading);
  const candidate = useSelector((state) => state.candidate.candidate);
  const comments = useSelector((state) => state.candidate.comments);
  const tickets = useSelector((state) => state.candidate.candidate.tickets);
  const positionList = useSelector((state) => state.createPosition.positionList);
  const projectCandidates = useSelector((state) => state.projects.candidates);
  const { state } = useLocation();

  useEffect(() => {
    dispatch(getCandidateById({ id: state.candidateId }));
    dispatch(SetSelectedComment(null));
    if (projectCandidates.length === 0) {
      dispatch(getCompanyCreateProjectData());
    }
  }, [state.candidateId]);

  const handleUpdateCandidate = () => {
    dispatch(SetUpsertModalCandidate({ ...candidate, candidateName: candidate.name }));

    dispatch(ShowUpsertCandidateModal({ upsertCandidateModalIsShown: true }));
    dispatch(ChangeIsUpdateOperation({ isUpdateOperation: true }));
  };

  return (
    <>
      {isLoading && <Overlay />}
      <Row className={"mx-0"}>
        <Col>
          <AdminBreadCrumb
            pageName={t("candidateBreadCrumbLabel")}
            backPageName={t("createCandidateBreadCrumbLabel")}
            backUrl={state.backUrl}
          >
            <Row className={"h-100"}>
              <Col>
                <Card className={"breadcrumb-card h-100"}>
                  <Row className={"h-100 mx-0  text-20 text-color-gray-dark fw-medium justify-content-evenly"}>
                    <Col xs={"auto"} className={"align-content-center"}>
                      <Row>
                        <Col xs={"auto"} className={"pe-0 align-content-center text-16 text-color-gray-light"}>
                          Aday Adı Soyadı:
                        </Col>
                        <Col>{candidate.name}</Col>
                      </Row>
                    </Col>
                    <Col xs={"auto"} className={"align-content-center"}>
                      <Row>
                        <Col xs={"auto"} className={"pe-0 align-content-center text-16 text-color-gray-light"}>
                          E-mail:
                        </Col>
                        <Col>{candidate.email}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={"auto"}>
                <Button
                  onClick={() => {
                    handleUpdateCandidate();
                  }}
                  className={"button secondary"}
                  size="sm"
                  style={{ height: "46px", width: "46px", padding: "4px", borderRadius: "10px" }}
                >
                  <IconSvg icon="edit" fill={"#0077B6"} />
                </Button>
              </Col>
            </Row>
          </AdminBreadCrumb>
        </Col>
      </Row>

      <Row className={"mx-0"}>
        <Col>
          <Card className={"default-card py-4"}>
            <Col className={"align-content-center"}>
              <Row className={"mx-0"}>
                <Col xs={"auto"} className="align-content-center ms-5">
                  <Row className={"justify-content-center"}>
                    <div
                      style={{
                        border: "1px solid #E7ECEF",
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        alignContent: "center",
                      }}
                    >
                      <Row className={"justify-content-center"}>
                        <IconSvg icon={"candidate"} />
                      </Row>
                    </div>
                  </Row>
                </Col>

                <Col className={" ms-5 border-end "} style={{ borderColor: "#E7ECEF" }}>
                  <DemographicInfoColumn candidate={candidate}></DemographicInfoColumn>
                </Col>
                <Col xs={"auto"} className={"align-content-center px-5"}>
                  <Row className={"justify-content-center"}>
                    <Col xs={"auto"} className={"align-content-center"}>
                      <Button
                        onClick={() => dispatch(SetSelectedComment({}))}
                        size={"sm"}
                        className={"button secondary"}
                        style={{ height: 46, width: 46, borderWidth: "1px" }}
                      >
                        <IconSvg icon={"comment"} fill={"#0077b6"}></IconSvg>
                      </Button>
                    </Col>
                    <Col xs={"auto"} className={"align-content-center"}>
                      <Button
                        size={"sm"}
                        className={"button secondary"}
                        style={{ height: 46, width: 46, borderWidth: "1px" }}
                      >
                        <IconSvg icon={"upload"} fill={"#0077b6"}></IconSvg>
                      </Button>
                    </Col>
                    <Col xs={"auto"}>
                      <Button
                        className={"button secondary h-100"}
                        onClick={() => {
                          dispatch(SetSelectedOptionByCandidateId({ value: candidate.candidateId }));
                          navigate("/project", {
                            state: {
                              candidateId: candidate.candidateId,
                              backUrl: "/Candidate/",
                            },
                          });
                          dispatch(SetSelectedPage({ url: "/project/" }));
                        }}
                      >
                        Değerleme Gönder
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Card>
        </Col>
      </Row>
      <Row>
        {tickets && (
          <Col xs={7}>
            <AssignedAssessments></AssignedAssessments>
          </Col>
        )}
        {comments && (
          <Col xs={5}>
            <Comment candidate={candidate}></Comment>
          </Col>
        )}
      </Row>

      <UpsertCommentModal></UpsertCommentModal>
      <UpsertCandidateModal positionList={positionList} backUrl={state.backUrl}></UpsertCandidateModal>
      <SessionsModal></SessionsModal>
    </>
  );
};
export default Candidate;
