import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ScrollableCard from "../../../../../SharedComponents/ScrollableCard";
import ApiHelper from "../../../../../../Helpers/ApiHelper";

const SessionImages = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedResult = useSelector((state) => state.candidate.selectedResult);
  const [imageArray, setImageArray] = useState([]);

  useEffect(() => {
    if (selectedResult?.jsonJpgNames) {
      setImageArray(JSON.parse(selectedResult?.jsonJpgNames));
    } else {
      setImageArray([]);
    }
  }, [selectedResult]);

  return (
    <Col>
      <ScrollableCard>
        <Row className={"h-100 mx-0 justify-content-center"}>
          {imageArray.map((imageName, i) => (
            <Col xs={"auto"} className={"p-0"} key={i}>
              <img className={"m-1"} alt={"image " + i} src={ApiHelper.baseURL + "wwwroot/candidate/" + imageName} />
            </Col>
          ))}
        </Row>
      </ScrollableCard>
    </Col>
  );
};
export default SessionImages;
