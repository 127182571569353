import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RemoveFromAlertArrayById } from "../Store";
import { Col, Row } from "react-bootstrap";

const AlertItem = ({ id, text, type }) => {
  const dispatch = useDispatch();
  const [remainingTime, setRemainingTime] = useState(5); // 5 seconds countdown
  const [isExiting, setIsExiting] = useState(false); // Track exit animation

  let alertClass = {};

  switch (type) {
    case "danger":
      alertClass = {
        bg: "bg-color-danger",
        loadingBarBg: "#81000c",
      };
      break;
    case "success":
      alertClass = {
        bg: "bg-color-success",
        loadingBarBg: "#008a46",
      };
      break;
    default:
      alertClass = {
        bg: "bg-color-success",
        loadingBarBg: "#008a46",
      };
  }

  useEffect(() => {
    const creationTime = Date.now();
    const endTime = creationTime + 5000; // 5 seconds from creation

    const interval = setInterval(() => {
      const now = Date.now();
      const timeLeft = Math.max(0, Math.round((endTime - now) / 1000)); // Calculate remaining time
      setRemainingTime(timeLeft);

      if (timeLeft <= 0) {
        clearInterval(interval);
        setIsExiting(true); // Trigger exit animation

        setTimeout(() => {
          dispatch(RemoveFromAlertArrayById(id)); // Remove alert after animation
        }, 1000); // Wait for animation duration (1s)
      }
    }, 100);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [dispatch, id]);

  // Calculate the percentage of time passed
  const progressPercentage = ((5 - remainingTime) / 4) * 100;

  return (
    <div
      className={`
        ${alertClass.bg} default-alert mb-3 me-3 rounded-4 overflow-hidden shadow-sm
        animate__animated ${isExiting ? "animate__fadeOutRight" : "animate__fadeInRight"}
      `}
    >
      <div className="text-color-white text-16 fw-medium" style={{ marginBottom: "8px" }}>
        <Row>
          <Col xs={"auto"}></Col>
          <Col>{text}</Col>
        </Row>
        <Row>
          <Col xs={"auto"}></Col>
          <Col> {remainingTime} seconds</Col>
        </Row>
      </div>
      <div style={{ height: "10px", background: "#e0e0e0", overflow: "hidden" }}>
        <div
          style={{
            width: `${progressPercentage}%`,
            height: "100%",
            background: alertClass.loadingBarBg,
            transition: "width 1s linear",
          }}
        ></div>
      </div>
    </div>
  );
};

export default AlertItem;
